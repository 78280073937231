<template>
	<div class="vx-row">
		<div class="vx-col md:w-1/1 w-full">
			<vx-card title="Form Setting Approval">
				<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important" v-if="create.id != 0">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Code</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						{{ create.code }}
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Event</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<multiselect class="selectExample" v-model="create.event" :options="option.event" :multiple="false"
							:allow-empty="false" :group-select="false" :max-height="160" :limit="4" @input="onSelectEvent"
							placeholder=" Type to search" track-by="ID" label="Reference" :disabled="false">
							<template slot="singleLabel" slot-scope="dt">
								<span class="option__desc">
									<span class="option__title">
										{{ dt.option.Reference }}
									</span>
								</span>
							</template>

							<template slot="option" slot-scope="dt">
								<div class="option__desc">
									<span class="option__title">
										{{ dt.option.Reference }}
									</span>
								</div>
							</template>
						</multiselect>
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Note</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-textarea v-model="create.note" />
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Model</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<ul>
							<li>
								<vs-radio v-model="create.model" vs-name="model" vs-value="1">Step</vs-radio>
							</li>
							<li>
								<vs-radio v-model="create.model" vs-name="model" vs-value="2">Final</vs-radio>
							</li>
						</ul>
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Status</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<ul>
							<li>
								<vs-radio v-model="create.status" vs-name="status" vs-value="1">Active</vs-radio>
							</li>
							<li>
								<vs-radio v-model="create.status" vs-name="status" vs-value="0">Non Active</vs-radio>
							</li>
						</ul>
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Source</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						{{ create.source }}
					</div>
				</div>
				<div class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>With Parameter</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<vs-switch v-model="create.withParam">
							<span slot="1">On</span>
							<span slot="0">Off</span>
						</vs-switch>
					</div>
				</div>
				<div v-if="create.withParam" class="vx-row mb-6 ml-4 mr-4">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Operation</span>
					</div>
					<div class="vx-col sm:w-4/5 w-full">
						<ul>
							<li>
								<vs-radio v-model="create.operation" vs-name="operation" vs-value="0">Or</vs-radio>
							</li>
							<li>
								<vs-radio v-model="create.operation" vs-name="operation" vs-value="1">And</vs-radio>
							</li>
						</ul>
					</div>
				</div>
				<vs-divider class="mb-2">-</vs-divider>
				<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Combination</span>
					</div>
					<template v-if="create.combination.length < 1">
						<vx-tooltip text="Add Combination">
							<vs-button size="small" @click="handleAddCombination()" color="green" icon-pack="feather"
								icon="icon-plus" />
						</vx-tooltip>
					</template>
					<template v-else>
						<template v-for="(combination, indexCombination) in create.combination">
							<div v-bind:key="indexCombination" class="vx-row mb-4 sm:w-4/5 w-full">
								<div v-bind:style="indexCombination != 0 ? 'display: flex; margin-left: 25%;' : ''
									" class="vx-col sm:w-1/5 w-full">
									<multiselect class="selectExample" v-model="combination.selected"
										:options="option.combination" :multiple="false" :allow-empty="true"
										:group-select="false" :max-height="160" :limit="4" selectLabel=""
										@input="handleChangeCombination(indexCombination)" deselectLabel=""
										placeholder=" Type to search" track-by="ID" label="Name" :disabled="false">
										<template slot="singleLabel" slot-scope="dt">
											<span class="option__desc">
												<span class="option__title">
													{{ dt.option.Name }}
												</span>
											</span>
										</template>

										<template slot="option" slot-scope="dt">
											<div class="option__desc">
												<span class="option__title">
													{{ dt.option.Name }}
												</span>
											</div>
										</template>
									</multiselect>
								</div>
								<div class="vx-col sm:w-2/5 w-full">
									<multiselect class="selectExample" v-model="combination.value"
										:options="combination.option" :multiple="true" :allow-empty="true"
										:group-select="false" :max-height="160" :limit="4" placeholder=" Type to search"
										track-by="ID" label="name" :disabled="false">
										<template slot="singleLabel" slot-scope="dt">
											<span class="option__desc">
												<span class="option__title">
													{{ dt.option.name }}
												</span>
											</span>
										</template>

										<template slot="option" slot-scope="dt">
											<div class="option__desc">
												<span class="option__title">
													{{ dt.option.name }}
												</span>
											</div>
										</template>
									</multiselect>
								</div>
								<div v-if="indexCombination == 0">
									<vx-tooltip text="Add Combination">
										<vs-button size="small" @click="handleAddCombination()" color="green"
											icon-pack="feather" icon="icon-plus" />
									</vx-tooltip>
								</div>
								<div v-else>
									<vx-tooltip text="Delete Combination">
										<vs-button size="small" @click="handleDeleteCombination(indexCombination)"
											color="red" icon-pack="feather" icon="icon-x" />
									</vx-tooltip>
								</div>
							</div>
						</template>
					</template>
				</div>

				<!-- excption section -->
				<vs-divider class="mb-2">-</vs-divider>
				<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
					<div class="vx-col sm:w-1/5 w-full">
						<span>Exception</span>
					</div>
					<template v-if="create.exception.length < 1">
						<vx-tooltip text="Add Exception">
							<vs-button size="small" @click="handleAddException()" color="green" icon-pack="feather"
								icon="icon-plus" />
						</vx-tooltip>
					</template>
					<template v-else>
						<template v-for="(exception, index) in create.exception">
							<div v-bind:key="index" class="vx-row mb-4 sm:w-4/5 w-full">
								<div v-bind:style="index != 0 ? 'display: flex; margin-left: 25%;' : ''
									" class="vx-col sm:w-1/5 w-full">
									<multiselect class="selectExample" v-model="exception.selected"
										:options="option.exception" :multiple="false" :allow-empty="true"
										:group-select="false" :max-height="160" :limit="4" selectLabel=""
										@input="handleChangeException(index)" deselectLabel=""
										placeholder=" Type to search" track-by="ID" label="Name" :disabled="false">
										<template slot="singleLabel" slot-scope="dt">
											<span class="option__desc">
												<span class="option__title">
													{{ dt.option.Name }}
												</span>
											</span>
										</template>

										<template slot="option" slot-scope="dt">
											<div class="option__desc">
												<span class="option__title">
													{{ dt.option.Name }}
												</span>
											</div>
										</template>
									</multiselect>
								</div>
								<div class="vx-col sm:w-2/5 w-full">
									<multiselect class="selectExample" v-model="exception.value"
										:options="exception.option" :multiple="true" :allow-empty="true"
										:group-select="false" :max-height="160" :limit="4" placeholder=" Type to search"
										track-by="ID" label="name" :disabled="false">
										<template slot="singleLabel" slot-scope="dt">
											<span class="option__desc">
												<span class="option__title">
													{{ dt.option.name }}
												</span>
											</span>
										</template>

										<template slot="option" slot-scope="dt">
											<div class="option__desc">
												<span class="option__title">
													{{ dt.option.name }}
												</span>
											</div>
										</template>
									</multiselect>
								</div>
								<div v-if="index == 0">
									<vx-tooltip text="Add Combination">
										<vs-button size="small" @click="handleAddException()" color="green"
											icon-pack="feather" icon="icon-plus" />
									</vx-tooltip>
								</div>
								<div v-else>
									<vx-tooltip text="Delete Combination">
										<vs-button size="small" @click="handleDeleteException(index)"
											color="red" icon-pack="feather" icon="icon-x" />
									</vx-tooltip>
								</div>
							</div>
						</template>
					</template>
				</div>

				<!-- div-parameter -->
				<vs-divider class="mb-2">-</vs-divider>
				<template v-if="create.withParam">
					<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
						<div class="vx-col sm:w-1/5 w-full">
							<span>Parameter</span>
						</div>
						<div class="vx-col sm:w-4/5 w-full">
							<multiselect class="selectExample" v-model="create.type" :options="option.type"
								:multiple="false" :allow-empty="false" :group-select="false" :max-height="160" :limit="4"
								placeholder=" Type to search" track-by="ID" label="Name" :disabled="false">
								<template slot="singleLabel" slot-scope="dt">
									<span class="option__desc">
										<span class="option__title">
											{{ dt.option.Name }}
										</span>
									</span>
								</template>

								<template slot="option" slot-scope="dt">
									<div class="option__desc">
										<span class="option__title">
											{{ dt.option.Name }}
										</span>
									</div>
								</template>
							</multiselect>
						</div>
					</div>
					<div class="vx-row mb-6 ml-4 mr-4">
						<div class="vx-col sm:w-4/5 w-full ml-auto">
							<vs-button v-on:click="handleAddParameter" class="mr-3 mb-2">Add Parameter</vs-button>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="vx-row mb-6 ml-4 mr-4" style="z-index: 99999 !important">
						<div class="vx-col sm:w-1/5 w-full">
							<span>Role</span>
						</div>
						<div class="vx-col sm:w-4/5 w-full">
							<multiselect class="selectExample" v-model="create.role" :options="option.role"
								:multiple="false" :allow-empty="false" :group-select="false" :max-height="160" :limit="4"
								placeholder=" Type to search" track-by="ID" label="DisplayName" :disabled="false">
								<template slot="singleLabel" slot-scope="dt">
									<span class="option__desc">
										<span class="option__title">
											{{ dt.option.DisplayName }}
										</span>
									</span>
								</template>

								<template slot="option" slot-scope="dt">
									<div class="option__desc">
										<span class="option__title">
											{{ dt.option.DisplayName }}
										</span>
									</div>
								</template>
							</multiselect>
						</div>
					</div>
					<div class="vx-row mb-6 ml-4 mr-4">
						<div class="vx-col sm:w-4/5 w-full ml-auto">
							<vs-button v-on:click="handleAddRole" class="mr-3 mb-2">
								Add Role
							</vs-button>
						</div>
					</div>
				</template>
				<vs-row v-if="create.withParam" vs-type="flex">
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
						Level
					</vs-col>
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="7">
						Parameter
					</vs-col>
					<!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
						Measurement
					</vs-col>
					<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="2">
						Form
					</vs-col>
					<vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="2">
						To
					</vs-col> -->
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
						Role
					</vs-col>
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
						Action
					</vs-col>
				</vs-row>
				<vs-row v-else vs-type="flex">
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
						Level
					</vs-col>
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
						Role
					</vs-col>
					<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
						Action
					</vs-col>
				</vs-row>
				<!-- line-parameter -->
				<vs-divider class="mb-2">Line</vs-divider>
				<template v-if="create.withParam">
					<template v-for="(dt, indexdt) in create.line">
						<vs-row v-bind:key="indexdt" style="border-bottom:1px solid rgba(0, 0, 0, 0.8)" vs-type="flex">
							<!-- <vs-card> -->
							<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
								{{ indexdt + 1 }}
							</vs-col>
							<vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="7">
								<vs-col vs-type="flex" vs-w="12" vs-justify="center" vs-align="center" v-bind:key="indexSub" v-for="(subDt,indexSub) in dt.parameter">
									<vs-col vs-justify="center"   vs-align="center" vs-w="5">{{subDt.type.Name}} ({{subDt.type.Info}}) </vs-col>
									<vs-col vs-justify="left" vs-align="center" vs-w="4" >
										<vs-input class=" ml-2 mr-2 mb-2" label="From" name="From" @keyup="subDt.from = formatPrice(subDt.from.toString())" v-on:change="minValue(indexdt, indexSub, subDt.type.Min)" v-model="subDt.from" />
									</vs-col>
									<!-- <vs-col vs-justify="right" vs-align="right" vs-w="4" >
										<vs-input class=" ml-2 mr-2 mb-2" label="To" name="To" @keypress="isNumber($event)" v-on:keyup="maxValue(indexdt, indexSub, subDt.type.Max)" v-model="subDt.to" />
									</vs-col> -->
								</vs-col>
							</vs-row>
							<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
								<div class="vx-row mt-4" style="z-index: {99999-idexdt} !important; width: 75%">
									<multiselect class="selectExample" v-model="dt.role" :options="option.role"
										:multiple="false" :allow-empty="true" :group-select="false" :show-labels="false"
										:max-height="160" :limit="4" @input="checkRoleDuplicate(indexdt)"
										placeholder=" Type to search" track-by="ID" label="DisplayName"
										:disabled="false">
										<template slot="singleLabel" slot-scope="dt">
											<span class="option__desc">
												<span class="option__title">
													{{ dt.option.DisplayName }}
												</span>
											</span>
										</template>

										<template slot="option" slot-scope="dt">
											<div class="option__desc">
												<span class="option__title">
													{{ dt.option.DisplayName }}
												</span>
											</div>
										</template>
									</multiselect>
								</div>
							</vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                <vx-tooltip text="Add Level">
                  <vs-button size="small" @click="handleAddLevel(indexdt)" color="green"
                    icon-pack="feather" icon="icon-plus" />
                </vx-tooltip>
                &nbsp;
                <vx-tooltip text="Delete">
                  <vs-button size="small" @click="handleDeleteLevel(indexdt)"
                    color="red" icon-pack="feather" icon="icon-x" />
                </vx-tooltip>
              </vs-col>
						</vs-row>
					</template>
				</template>
				<template v-else>
					<template v-for="(dt, indexdt) in create.line">
						<vs-row v-bind:key="indexdt" class="mb-3" vs-type="flex">
							<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
								{{ indexdt + 1 }}
							</vs-col>
							<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
								{{ dt.role.DisplayName }}
							</vs-col>
							<vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
								<vx-tooltip text="Delete">
									<vs-button size="small" @click="handleRole(indexdt)" color="red" icon-pack="feather"
										icon="icon-x" />
								</vx-tooltip>
							</vs-col>
						</vs-row>
					</template>
				</template>
				<vs-divider class="mb-2"></vs-divider>
				<vs-row class="mb-3" vs-justify="center" v-if="create.id == 0">
					<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
						<vs-button @click="handleSubmit()" color="primary" icon-pack="feather">Submit </vs-button>
					</vs-col>
				</vs-row>
				<vs-row class="mb-3" vs-justify="center" v-else>
					<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
						<vs-button @click="handleEdit()" color="primary" icon-pack="feather">Submit </vs-button>
					</vs-col>
				</vs-row>

			</vx-card>
		</div>
	</div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
	components: {
		draggable
	},
	created() { },
	props: {
		id: Number
	},
	data() {
		return this.initialState();
	},
	watch: {
		"create.source"(val) {
			if (val == "OMS") {
				this.option.role = this.option.roleOms;
			} else if (val == "WMS") {
				this.option.role = this.option.roleWms;
			} else if (val == "SFA") {
				this.option.role = this.option.roleSfa;
				console.log(this.option.roleSfa)
			}
		},
		"create.withParam"(val) {
			if (val === true) {
				this.create.withParam = 1
			}else if (val === false) {
				this.create.withParam = 0
			}
			console.log(val)
			this.create.line = [];
			this.create.operation = 0;
		},
	},
	mounted() {
		console.log(this.$route, this.$router)
		if (this.$route.params.id) {
			this.create.id = parseInt(this.$route.params.id)
		}
		this.initType();
	},
	methods: {
		initialState() {
			return {
				create: {
					id: 0,
					note: '',
					event: {},
					type: {},
					source: "WMS",
					operation: 0,
					model: 1,
					status: 1,
					withParam: 0,
					combination: [],
					exception:[],
					line: [],
				},
				option: {
					event: [],
					combination: [],
					exception:[],
					type: [],
					roleOms: [],
					roleWms: [],
					roleSfa: [],
					role: [],
				},
				selected: {},
				responseData: {},
				users: [],
				type: 0,
			};
		},
		handleSubmit() {
			this.$vs.loading();
			console.log(this.create.line)
			if (this.create.operation == false) {
				this.create.operation = 0
			} else {
				this.create.operation = 1
			}
			this.create.model = parseInt(this.create.model)
			this.create.status = parseInt(this.create.status)
			
			if (this.create.withParam === false) {
				this.create.withParam = 0
			} else if (this.create.withParam === true) {
				this.create.withParam = 1
			}
			console.log("approvalMatrixLine", this.create.line);
			this.create.line.map((v, i) => {
				if(v.parameter) {
					v.parameter.map((subV, subI) => {
						console.log(this.create.line[i].parameter[subI].to)
						this.create.line[i].parameter[subI].to = parseInt(this.create.line[i].parameter[subI].to)
						this.create.line[i].parameter[subI].from = parseInt(this.create.line[i].parameter[subI].from.toString().replace(/,/g, ''))
					})
				}
			})
			// this.create.line = line
			console.log(this.create)
			this.$http
				.post("api/v1/approval/matrix/store", this.create)
				.then((resp) => {
					console.log(resp)
					this.$vs.loading.close()
					this.$router.push("/setting/approval");
				})
		},
		handleEdit() {
			this.$vs.loading()
			const line = this.create.line
			this.create.operation = this.create.operation ? 1 : 0
			this.create.model = parseInt(this.create.model)
			this.create.status = parseInt(this.create.status)
			this.create.withParam = this.create.withParam ? 1 : 0
			console.log(this.create)
			this.create.line = line
			console.log("approvalMatrixLine", this.create.line);
			this.create.line.map((v, i) => {
				if(v.parameter) {
					v.parameter.map((subV, subI) => {
						console.log(this.create.line[i].parameter[subI].to)
						this.create.line[i].parameter[subI].to = parseInt(this.create.line[i].parameter[subI].to)
						this.create.line[i].parameter[subI].from = parseInt(this.create.line[i].parameter[subI].from.toString().replace(/,/g, ''))
					})
				}
			})
			this.$http
				.put("api/v1/approval/matrix/store", this.create)
				.then((resp) => {
					this.$vs.loading.close()
					console.log(resp)
					this.$router.push("/setting/approval");
				})
		},
		handleAddCombination() {
			this.create.combination.push({ option: [], value: [] });
		},
		handleAddException() {
			this.create.exception.push({ option: [], value: [] });
		},
		handleDeleteCombination(index) {
			this.create.combination = this.create.combination.filter((v, i) => {
				return i != index
			});
		},
		handleDeleteException(index) {
			this.create.exception = this.create.exception.filter((v, i) => {
				return i != index
			});
		},
		isNumber: function (evt) {
			evt = evt ? evt : window.event;
			var charCode = evt.which ? evt.which : evt.keyCode;
			if (
				charCode > 31 &&
				(charCode < 48 || charCode > 57) &&
				charCode !== 46
			) {
				evt.preventDefault();
			} else {
				return true;
			}
		},
		minValue(index, subindex, min) {
			console.log(index, subindex, min);
			console.log(this.create.line[index].parameter[subindex].from.length);
			if (this.create.line[index].parameter[subindex].from.length.toString().replace(/,/g, '') <= 21) {
				if (parseInt(this.create.line[index].parameter[subindex].from) < min) {
					this.create.line[index].parameter[subindex].from = min;
					this.create.line[index].parameter[subindex].to = 0;
				} else {
					if (index != 0) {
						if (parseInt(this.create.line[index-1].parameter[subindex].from.toString().replace(/,/g, '')) > parseInt(this.create.line[index].parameter[subindex].from.toString().replace(/,/g, ''))) {
							this.create.line[index].parameter[subindex].from = (this.create.line[index-1].parameter[subindex].from) ;
							this.create.line[index].parameter[subindex].to = 0;
						}
					}
				}
			} else {
				this.$vs.notify({
					color: "warning",
					title: "Warning",
					text: "Max 16 digits",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				this.create.line[index].parameter[subindex].from = this.create.line[index].parameter[subindex].from.toString().replace(/,/g, '').substr(0,(16)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
			}
		},
		maxValue(index, subindex, max) {
			console.log(index);
			if(this.create.line[index].parameter[subindex].to.length <= 16) {
				if (this.create.line[index].parameter[subindex].to > max && max != 0) {
					this.create.line[index].parameter[subindex].to = max;
				}
			} else {
				this.$vs.notify({
					color: "warning",
					title: "Warning",
					text: "Max 16 digits",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				this.create.line[index].parameter[subindex].to = this.create.line[index].parameter[subindex].to.substr(0,(16))	
			}
		},
		getData() {
			const params = {
				id: this.create.id,
			}
			this.$http
				.get(
					`api/v1/approval/matrix`, { params }
				)
				.then((resp) => {
					console.log(this.option.event, 'option')
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						console.log(resp)
						this.selected = resp.data
						this.create.operation = this.selected.approvalMatrix.Operation
						this.create.model = this.selected.approvalMatrix.Model
						this.create.status = this.selected.approvalMatrix.IsActive
						if (this.selected.approvalMatrixLine.length < 0) {
							this.selected.approvalMatrixLine = []
						}
						if (this.selected.approvalMatrixLine.length > 0 && this.selected.approvalMatrixLine[0].ApprovalTypeLineID != 0) {
							this.create.withParam = 1
							this.selected.approvalMatrixLine.forEach(e => {
								e.ValueFrom = this.formatPrice(e.ValueFrom)
							});
						} else {
							this.create.withParam = 0
						}
						const selectedEvent = this.option.event.filter((v) => {
							return v.ID == this.selected.approvalMatrix.ApprovalTypeID
						})
						console.log(this.selected, "this.create.approvalMatrixLine");
						this.create.event = selectedEvent[0]
						this.create.source = selectedEvent[0].Source
						this.create.note = this.selected.approvalMatrix.Note
						this.create.code = this.selected.approvalMatrix.Code
						this.onSelectEvent(selectedEvent[0]);
						// this.create.source = this.selected.approvalMatrix.Source
						setTimeout(function () {}, 2000);
						this.$vs.loading.close();
					} else {
						this.$vs.loading.close();
					}
				});
		},
		initType() {
			this.$vs.loading();
			this.$http
				.get(
					`api/v1/approval/type/data-table?search=&length=10000&page=1&order=asc&sort=id`
				)
				.then((resp) => {
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						this.$vs.loading.close();
						this.option.event = resp.data.approvalType;
						this.onSelectEvent(this.option.event[0]);
						if (this.create.id > 0) {
							this.getData(this.create.id)
						}
					} else {
						this.$vs.loading.close();
					}
				});
		},
		checkRoleDuplicate(index) {
			const role = this.create.line[index].role;
			const checkOther = this.create.line.filter((v, i) => {
				return i != index;
			});
			this.$nextTick(() => {
				checkOther.map((v) => {
					console.log(v, role)
					if (v.role.ID == role.ID) {
						this.create.line[index].role = {};
						this.$vs.notify({
							color: "danger",
							title: "Failed",
							text: "Please choose other role",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
						return;
					}
				});
			});
		},
		handleCombinationValue(selected, index, setValue = []) {
			this.$vs.loading();
			this.$http
				.get(
					`api/v1/approval/matrix/combination-line?code=${selected.Code}`
				)
				.then((resp) => {
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						this.$vs.loading.close();
						console.log(this.create.combination[index].value, setValue, resp.data.combination)
						this.create.combination[index].value = []
						this.create.combination[index].option = resp.data.combination
						setValue.map(v => {
							selected = resp.data.combination.filter(v2 => {
								return v2.ID == v.ReferenceID
							})
							this.create.combination[index].value.push(selected[0])
						})
					} else {
						this.$vs.loading.close();
					}
				});
		},
		handleExceptionValue(selected, index, setValue = []) {
			this.$vs.loading();
			this.$http
				.get(
					`api/v1/approval/matrix/exception-line?code=${selected.Code}`
				)
				.then((resp) => {
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						this.$vs.loading.close();
						console.log(this.create.exception[index].value, setValue, resp.data.exception)
						this.create.exception[index].value = []
						this.create.exception[index].option = resp.data.exception
						setValue.map(v => {
							selected = resp.data.exception.filter(v2 => {
								return v2.ID == v.ReferenceID
							})
							this.create.exception[index].value.push(selected[0])
						})
					} else {
						this.$vs.loading.close();
					}
				});
		},
		handleChangeCombination(indexCombination) {
			const combination = this.create.combination[indexCombination].selected;
			const checkOther = this.create.combination.filter((v, i) => {
				return i != indexCombination;
			});
			this.$nextTick(() => {
				checkOther.map((v) => {
					console.log(v, combination)
					if (v.selected.Name == combination.Name) {
						this.create.combination[indexCombination].selected = {};
						this.$vs.notify({
							color: "danger",
							title: "Failed",
							text: "Please choose other combination",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
						return;
					}
				});
				this.handleCombinationValue(combination, indexCombination)
			});

		},
		handleChangeException(index) {
			const exception = this.create.exception[index].selected;
			const checkOther = this.create.exception.filter((v, i) => {
				return i != index;
			});
			this.$nextTick(() => {
				checkOther.map((v) => {
					console.log(v, exception)
					if (v.selected.Name == exception.Name) {
						this.create.exception[index].selected = {};
						this.$vs.notify({
							color: "danger",
							title: "Failed",
							text: "Please choose other exception",
							position: "top-right",
							iconPack: "feather",
							icon: "icon-x-circle",
						});
						return;
					}
				});
				this.handleExceptionValue(exception, index)
			});

		},
		createDetail() {
			this.create.line = []
			this.create.combination = []
			this.create.exception = []
			if (this.selected.approvalMatrixLine.length < 0) {
				this.selected.approvalMatrixLine = []
			}
			console.log(this.selected.approvalMatrixLine, 'this.selected.approvalMatrixLine')
			this.selected.approvalMatrixLine.map((v, i) => {
				const selectedType = this.option.type.filter(row => {
					console.log(row, v)
					return row.ID == v.ApprovalTypeLineID
				})
				const type = selectedType[0]
				const role = this.option.role.filter(row => {
					return v.RoleID == row.ID
				})
				if (v.ApprovalTypeLineID != 0) {
					const newData = {
						parameter: [
							{
								type: type,
								name: type.Name,
								from: v.ValueFrom,
								to: 0,
							}
						],
						role: role[0],
						level: v.Level,
						operation: false,
					};
					// const newData = {
					// 	type: type,
					// 	name: type.Name,
					// 	level: [
					// 		{
					// 			from: v.ValueFrom,
					// 			to: v.ValueTo,
					// 			role: role[0],
					// 		},
					// 	],
					// };
					const checkType = this.create.line.filter((v) => {
						return v.level == newData.level;
					});
					if (checkType.length > 0) {
						// checkType[0].level.push({
						// 	from: v.ValueFrom,
						// 	to: v.ValueTo,
						// 	role: role[0],
						// })
						this.create.line[newData.level - 1].parameter.push({
								type: type,
								name: type.Name,
								from: v.ValueFrom,
								to: 0,		
						})
						
					} else {
						this.create.line.push(newData);
					}
				} else {
					const newData = {
						role: role[0]
					};
					console.log(newData, 'newData')
					const checkType = this.create.line.filter((v) => {
						return v.role.Name == role.Name;
					});
					if (checkType.length > 0) {

					} else {
						this.create.line.push(newData);
					}
				}
			});
			console.log(this.selected.approvalCombination, "combination")
			this.selected.approvalCombination.map((v, i) => {
				this.create.combination.push({ option: [], value: [], selected: {} });
				const selected = this.option.combination.filter((row) => {
					return row.ID == v.selected.ApprovalTypeCombinationID
				})
				if (selected.length > 0) {
					this.create.combination[i].selected = selected[0]
					this.handleCombinationValue(selected[0], i, v.value)
				}

			})

			console.log(this.selected.approvalException, "exception")
			this.selected.approvalException.map((v, i) => {
				this.create.exception.push({ option: [], value: [], selected: {} });
				const selected = this.option.exception.filter((row) => {
					return row.ID == v.selected.ApprovalTypeExceptionID
				})
				if (selected.length > 0) {
					this.create.exception[i].selected = selected[0]
					this.handleExceptionValue(selected[0], i, v.value)
				}

			})
		},
		onSelectEvent(selected) {
			console.log("onSelectEvent")
			this.$vs.loading();
			this.$http
				.get(
					`api/v1/approval/matrix/general-form?approval_type_id=${selected.ID}`
				)
				.then((resp) => {
					if (resp.code == 500) {
						this.$vs.loading.close();
					} else if (resp.code == 200) {
						this.$vs.loading.close();
						this.option.type = resp.data.approvalTypeLine;
						this.option.roleOms = resp.data.roleOms;
						this.option.roleWms = resp.data.roleWms;
						this.option.roleWmsOld = resp.data.roleWmsOld;
						this.option.roleSfa = resp.data.roleSfa;
						this.option.combination = resp.data.approvalTypeCombination;
						this.option.exception = resp.data.approvalTypeException;
						this.create.line = [];
						this.create.source = selected.Source
						if (this.create.source == "OMS") {
							this.option.role = this.option.roleOms;
						} else if (this.create.source == "WMS") {
							this.option.role = this.option.roleWms;
						} else if (this.create.source == "SFA") {
							this.option.role = this.option.roleSfa;
						} else if (this.create.source == "WMS-OLD") {
							this.option.role = this.option.roleWmsOld;
						}
						if (this.create.id > 0) {
							this.createDetail()
						}
					} else {
						this.$vs.loading.close();
					}
				});
		},
		handleAddParameter() {
			const type = this.create.type;
			
			const newData = {
				parameter: [
					{
						type: type,
						name: type.Name,
						from: type.Min,
						to: 0,
					}
				],
				role: {},
				level: 1,
				operation: false,
			};
			if (Object.keys(type).length < 1) {
				this.$vs.notify({
					color: "danger",
					title: "Failed",
					text: "Please choose one parameter",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				return;
			}

			if (this.create.line.length > 0) {
				const checkType = this.create.line[0].parameter.filter((v) => {
					return v.name == type.Name;
				});
				if (checkType.length > 0) {
					this.$vs.notify({
						color: "danger",
						title: "Failed",
						text: "Please choose different parameter",
						position: "top-right",
						iconPack: "feather",
						icon: "icon-x-circle",
					});
					return;
				}
				this.create.line.map((v,i) => {
					v.parameter.push({
						type: type,
						name: type.Name,
						from: type.Min,
						to: 0,		
					})
				})
				return 
			}
			
			this.create.line.push(newData);
			return 
		},
		handleAddRole() {
			const role = this.create.role;
			const newData = {
				role: role
			};
			const checkType = this.create.line.filter((v) => {
				return v.role.DisplayName == role.DisplayName;
			});
			if (Object.keys(role).length < 1) {
				this.$vs.notify({
					color: "danger",
					title: "Failed",
					text: "Please choose one role",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				return;
			}
			if (checkType.length > 0) {
				this.$vs.notify({
					color: "danger",
					title: "Failed",
					text: "Please choose different role",
					position: "top-right",
					iconPack: "feather",
					icon: "icon-x-circle",
				});
				return;
			}
			this.create.line.push(newData);
		},

		handleAddLevel(index) {
			let params = []
			this.create.line[0].parameter.map(v => {
				params.push({
					type: v.type,
					name: v.type.Name,
					from: v.type.Min,
					to: v.type.Max,
				})
			})
			let newLine = Object.assign({}, {
				parameter: params,
				role: {},
				level: this.create.line.length + 1,
				operation: false,
			});
			this.create.line = [...this.create.line, ...[newLine]]
		},
		handleRole(index) {
			const newLevel = this.create.line.filter((v, i) => {
				return i != index;
			});
			console.log(newLevel.length, newLevel, this.create.line)
			if (newLevel.length < 1) {
				this.create.line = this.create.line.filter((v, i) => {
					return i != index;
				});
			} else {
				this.create.line = newLevel;
			}
		},
		handleDeleteLevel(index) {
			const newLevel = this.create.line.filter((v, i) => {
				return i != index;
			});
			if (newLevel.length < 1) {
				this.create.line = this.create.line.filter((v, i) => {
					return i != index;
				});
			} else {
				this.create.line = newLevel;
			}
		},
		paramData() {
			return {};
		},
		formatPrice(angka, prefix = "") {
			return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
	},
};
</script>